var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"container-fluid"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-8 col-md-11 col-12"},[_c('h1',[(_vm.$can('read', 'Company', _vm.companyId))?_c('router-link',{attrs:{"to":{ name: 'Company', params: { companyId: _vm.companyId } }}},[_vm._v(" "+_vm._s(_vm.companyName)+" / ")]):_vm._e(),(_vm.$can('read', 'Company', 'Employees', _vm.companyId))?_c('router-link',{attrs:{"to":{ name: 'CompanyEmployeeList', params: { companyId: _vm.companyId } }}},[_vm._v(" "+_vm._s(_vm.$t('companyBase.employeeList'))+" / ")]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'User', params: { id: _vm.employeeId } }}},[_vm._v(" "+_vm._s(_vm.fullName)+" ")]),_c('p')],1),_c('h2',[(!_vm.isActive)?_c('b-badge',{attrs:{"variant":"warning"}},[_vm._v("Aftalen er inaktivt")]):_vm._e()],1),_c('p',[_vm._v(_vm._s(_vm.companyCvr))])])]),_c('NavigationSlide',{attrs:{"nav":[
			{
				name: 'CompanyEmployeeInfo',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: 'Brugeren',
				icon: 'info-circle',
				hidden: !_vm.$can('read', 'User'),
			},
			{
				name: 'CompanyEmployeeAdvisor',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.advisors'),
				icon: 'hands-helping',
				hidden: !_vm.$can('read', 'EmployeePolicy', 'CompanyAdvisors'),
			},
			{
				name: 'CompanyEmployeeCondition',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.condition'),
				icon: 'file-contract',
				hidden: !_vm.canIreadThis('EmployeeCondition', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeAbsence',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: 'Orlov',
				icon: 'pause',
				hidden: !_vm.canIreadThis('Absence', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeSickLeave',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: 'Sygdom',
				icon: 'disease',
				hidden: !_vm.canIreadThis('SickLeave', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeResignation',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.registration'),
				icon: 'file-contract',
				hidden: !_vm.canIreadThis('EmployeeCondition', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeIndividual',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.individual'),
				icon: 'user',
				hidden: !_vm.canIreadThis('IndividualCondition', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeePrivate',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.private'),
				icon: 'user-shield',
				hidden: !_vm.canIreadThis('PrivateCondition', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeePensionList',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.pension'),
				icon: 'list-alt',
				hidden: !_vm.canIreadThis('EmployeePensions', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeInsuranceList',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.insurance'),
				icon: 'house-damage',
				hidden: !_vm.canIreadThis('Insurances', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeCoverageOverview',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.coverageOverview'),
				icon: 'eye',
				hidden: !_vm.canIreadThisCoverageOverview('read', 'CoverageOverview', _vm.employeeId),
			},
			{
				name: 'CompanyEmployeeLogs',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.logs'),
				icon: 'server',
				hidden: !_vm.$can('read', 'EmployeePolicy', 'Logs'),
			},
			{
				name: 'CompanyEmployeeSettings',
				params: '{ companyId: ' + _vm.companyId + ', employeeId: ' + _vm.employeeId + '}',
				text: _vm.$t('employeeBase.settings'),
				icon: 'cog',
				hidden: !_vm.$can('read', 'Settings'),
			} ]}}),(_vm.isReady)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('router-view')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }