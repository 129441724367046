<template>
	<section class="container-fluid">
		<div class="row justify-content-center">
			<div class="col-lg-8 col-md-11 col-12">
				<h1>
					<router-link :to="{ name: 'Company', params: { companyId: companyId } }" v-if="$can('read', 'Company', companyId)">
						{{ companyName }} /
					</router-link>
					<router-link :to="{ name: 'CompanyEmployeeList', params: { companyId: companyId } }" v-if="$can('read', 'Company', 'Employees', companyId)">
						{{ $t('companyBase.employeeList') }} /
					</router-link>
					<router-link :to="{ name: 'User', params: { id: employeeId } }"> {{ fullName }} </router-link>
					<p></p>
				</h1>
				<!-- <h2><b-badge variant="danger" v-if="isResigned">Medarbejderen er fratrådt</b-badge></h2> -->
				<h2><b-badge variant="warning" v-if="!isActive">Aftalen er inaktivt</b-badge></h2>
				<p>{{ companyCvr }}</p>
			</div>
		</div>

		<NavigationSlide
			:nav="[
				{
					name: 'CompanyEmployeeInfo',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: 'Brugeren',
					icon: 'info-circle',
					hidden: !$can('read', 'User'),
				},
				{
					name: 'CompanyEmployeeAdvisor',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.advisors'),
					icon: 'hands-helping',
					hidden: !$can('read', 'EmployeePolicy', 'CompanyAdvisors'),
				},
				{
					name: 'CompanyEmployeeCondition',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.condition'),
					icon: 'file-contract',
					hidden: !canIreadThis('EmployeeCondition', employeeId),
				},
				{
					name: 'CompanyEmployeeAbsence',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: 'Orlov',
					icon: 'pause',
					hidden: !canIreadThis('Absence', employeeId),
				},
				{
					name: 'CompanyEmployeeSickLeave',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: 'Sygdom',
					icon: 'disease',
					hidden: !canIreadThis('SickLeave', employeeId),
				},
				{
					name: 'CompanyEmployeeResignation',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.registration'),
					icon: 'file-contract',
					hidden: !canIreadThis('EmployeeCondition', employeeId),
				},
				{
					name: 'CompanyEmployeeIndividual',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.individual'),
					icon: 'user',
					hidden: !canIreadThis('IndividualCondition', employeeId),
				},
				{
					name: 'CompanyEmployeePrivate',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.private'),
					icon: 'user-shield',
					hidden: !canIreadThis('PrivateCondition', employeeId),
				},
				{
					name: 'CompanyEmployeePensionList',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.pension'),
					icon: 'list-alt',
					hidden: !canIreadThis('EmployeePensions', employeeId),
				},
				{
					name: 'CompanyEmployeeInsuranceList',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.insurance'),
					icon: 'house-damage',
					hidden: !canIreadThis('Insurances', employeeId),
				},
				{
					name: 'CompanyEmployeeCoverageOverview',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.coverageOverview'),
					icon: 'eye',
					hidden: !canIreadThisCoverageOverview('read', 'CoverageOverview', employeeId),
				},
				{
					name: 'CompanyEmployeeLogs',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.logs'),
					icon: 'server',
					hidden: !$can('read', 'EmployeePolicy', 'Logs'),
				},
				{
					name: 'CompanyEmployeeSettings',
					params: '{ companyId: ' + companyId + ', employeeId: ' + employeeId + '}',
					text: $t('employeeBase.settings'),
					icon: 'cog',
					hidden: !$can('read', 'Settings'),
				},
			]"
		/>

		<div class="row" v-if="isReady">
			<div class="col">
				<router-view />
			</div>
		</div>
	</section>
</template>

<script>
import NavigationSlide from '@/modules/global/components/NavigationSlide.vue'
import { subject } from '@casl/ability'
import Swal from 'sweetalert2'

export default {
	name: 'BaseEmployee',
	components: { NavigationSlide },
	data() {
		return {
			isReady: false,
		}
	},
	async mounted() {
		var request1 = this.$store.dispatch('employeeVuex/getCompany', this.companyId)
		var request2 = this.$store.dispatch('employeeVuex/getUser', this.employeeId)

		var request3 = this.$store.dispatch('employeeVuex/getEmployeePolicy', {
			employeeId: this.employeeId,
			companyId: this.companyId,
			policyId: this.policyId,
		})

		var requests = [request1, request2, request3]
		try {
			await Promise.all(requests)
			this.$store.dispatch('employeeVuex/getCompanyAdvisors', this.companyId)
			if (!this.policyId) {
				throw new Error('Der er ikke oprettet en aftale for denne bruger')
			}
		} catch (err) {
			console.log(err)
			this.toast('error', err.message, false)
			if (err.message == 'Der er ikke oprettet en aftale for denne bruger') {
				if (this.isEitherAdminOrAdvisor) {
					var companyId = this.companyId
					var employeeId = this.employeeId
					Swal.fire({
						title: 'Opret aftale',
						text: 'Der er ikke oprettet en aftale for denne medarbejder. Vil du oprette en ny aftale til medarbejderen?',
						icon: 'warning',
						showCancelButton: true,
						confirmButtonColor: '#3085d6',
						cancelButtonColor: '#d33',
						confirmButtonText: 'Ja opret!',
						showLoaderOnConfirm: true,
						preConfirm: async () => {
							try {
								var result = await this.$store.dispatch('employeeVuex/addEmployeePolicyForTheUser', { companyId, employeeId })
								console.log(result)
							} catch (error) {
								Swal.showValidationMessage(`Request failed: ${error}`)
							}
						},
					}).then(async (result) => {
						if (result.isConfirmed) {
							Swal.fire('Oprettet!', 'Der er tilknyttet en aftale for medarbejderen!', 'success')
							this.toast('Success', 'Medarbejderens aftale er blevet oprettet', true)
							// Goto employeeList
							this.$router.push({
								name: 'CompanyEmployeeList',
								params: { companyId },
							})
						}
					})
				}
			}
		}
		this.$emit('updateLoader')
		this.isReady = true
	},
	methods: {
		canIreadThis(field, employeeId) {
			var result = this.$store.getters['auth/ability'].can('read', subject('EmployeePolicy', { employeeId: employeeId }), field)
			return result
		},
		canIreadThisCoverageOverview(field, employeeId) {
			if (this.isAdmin) {
				return true
			} else {
				if (this.isEmployee) {
					this.$store.getters['employeeVuex/employeePolicy'].employeeId == this.uid
				} else {
					return false
				}
			}
		},
	},
	computed: {
		companyCvr() {
			return this.company?.companyInfo?.cvr ?? ' '
		},
		companyName() {
			return this.company?.companyInfo?.name ?? ' '
		},
		fullName() {
			var name = this.employeeInfo?.firstName + ' ' + this.employeeInfo?.lastName
			return name ?? ' '
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		company() {
			return this.$store.getters['employeeVuex/company']
		},
		employeeInfo() {
			return this.$store.getters['employeeVuex/user']
		},
		isActive() {
			var isActive = this.$store.getters['employeeVuex/employeePolicy']?.isActive
			return isActive
		},
		uid() {
			return this.$store.getters['auth/user'].uid
		},
		isAdmin() {
			return this.$store.getters['auth/isAdmin']
		},
		isAdvisor() {
			return this.$store.getters['auth/isAdvisor']
		},
		isEitherAdminOrAdvisor() {
			return this.isAdmin || this.isAdvisor
		},
		policyId() {
			return this.$route.params.policyId
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		isResigned() {
			return this.employeePolicy?.employeeCondition?.resignationDate != null
		},
	},
	destroyed() {
		this.$store.dispatch('employeeVuex/clearState')
	},
}
</script>

<style></style>
